import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDPvh3GDRQ6vk5YZZVL2ifAVwssWQT3v7I",
  authDomain: "twcaptcha-e8b0f.firebaseapp.com",
  projectId: "twcaptcha-e8b0f",
  storageBucket: "twcaptcha-e8b0f.appspot.com",
  messagingSenderId: "931207488219",
  appId: "1:931207488219:web:9ae695be6b6688297362fe",
  measurementId: "G-8NMCYHXPBF",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
