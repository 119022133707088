<template>
  <metainfo>
    <template v-slot:title="{ content }">
      {{ content ? `TWCaptcha ` : `TWCaptcha` }}
    </template>
  </metainfo>
  <router-view />
</template>

<script>
export default {
  metaInfo: () => ({
    title: "",
  }),
};
</script>

<style lang="scss"></style>
